.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  width: 100%;
  display: block;
}

.logoText {
  text-align: center;
  font-size: 2.25rem;
}

.logo-wrap {
  text-align: center;
}

@media only screen and (width >= 320px) and (width <= 480px) {
  .logoText {
    text-align: center;
    font-size: 1.25rem;
  }

  .logo-wrap {
    text-align: center;
  }
}

@media only screen and (width <= 320px) {
  .logoText {
    text-align: center;
    font-size: 1.25rem;
  }

  .logo-wrap {
    text-align: center;
  }
}

@media only screen and (width >= 481px) and (width <= 768px) {
  .logoText {
    text-align: center;
    font-size: 1.45rem;
  }

  .logo-wrap {
    text-align: center;
  }
}

lite-youtube {
  contain: content;
  cursor: pointer;
  background-color: #000;
  background-position: center;
  background-size: cover;
  max-width: 720px;
  display: block;
  position: relative;
}

lite-youtube:before {
  content: attr(data-title);
  color: #eee;
  text-shadow: 0 0 2px #00000080;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-image: linear-gradient(#000000ab 0%, #0000008a 14%, #00000026 54%, #0000000d 72%, #0000 94%);
  width: 100%;
  height: 99px;
  padding: 25px 20px;
  font-family: YouTube Noto, Roboto, Arial, Helvetica, sans-serif;
  font-size: 18px;
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

lite-youtube:hover:before {
  color: #fff;
}

lite-youtube:after {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

lite-youtube > iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

lite-youtube > .lty-playbtn {
  cursor: pointer;
  z-index: 1;
  filter: grayscale();
  background: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 68 48\"><path d=\"M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z\" fill=\"red\"/><path d=\"M45 24 27 14v20\" fill=\"white\"/></svg>") center / 68px 48px no-repeat;
  border: 0;
  width: 100%;
  height: 100%;
  transition: filter .1s cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
}

lite-youtube:hover > .lty-playbtn, lite-youtube .lty-playbtn:focus {
  filter: none;
}

lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated:before, lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}
/*# sourceMappingURL=index-2.4174021d.css.map */
