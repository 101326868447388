/* @import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities'; */

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.logoText {
  font-size: 2.25rem;
  text-align: center;
}

.logo-wrap {
  text-align: center;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */

  .logoText {
    font-size: 1.25rem;
    text-align: center;
  }

  .logo-wrap {
    text-align: center;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .logoText {
    font-size: 1.25rem;
    text-align: center;
  }

  .logo-wrap {
    text-align: center;
  }
}

/* Tablet ----------- */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* Styles */

  .logoText {
    font-size: 1.45rem;
    text-align: center;
  }

  .logo-wrap {
    text-align: center;
  }
}

/* youtube */

lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 720px;
}

/* gradient */
lite-youtube::before {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 0;
  /* Pixel-perfect port of YT's gradient PNG, using https://github.com/bluesmoon/pngtocss plus optimizations */
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 67%) 0%,
    rgb(0 0 0 / 54%) 14%,
    rgb(0 0 0 / 15%) 54%,
    rgb(0 0 0 / 5%) 72%,
    rgb(0 0 0 / 0%) 94%
  );
  height: 99px;
  width: 100%;
  font-family: 'YouTube Noto', Roboto, Arial, Helvetica, sans-serif;
  color: hsl(0deg 0% 93.33%);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  padding: 25px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

lite-youtube:hover::before {
  color: white;
}

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  display: block;
  /* Make the button element cover the whole area for a large hover/click target� */
  width: 100%;
  height: 100%;
  /* �but visually it's still the same size */
  background: no-repeat center/68px 48px;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  position: absolute;
  cursor: pointer;
  z-index: 1;
  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: 0;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}
lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
